import React from 'react'

const VGBG = () => {
  return (
    <div className='w-full h-full bg-white absolute top-0 left-0 -z-30'>
      <div className='w-full h-full absolute top-0 left-0 bg-vg-bg bg-repeat bg-small opacity-[2.5%]'/>
    </div>
  )
}

export default VGBG