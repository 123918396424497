import { ArrowRightIcon } from "@heroicons/react/solid";
import React from "react";

const OrangeButton = ({ title, arrow, onClick }: any) => {
  return (
    <button
      onClick={onClick}
      className="py-[10px] px-8 w-full rounded-full cursor-pointer bg-btn-gradient flex gap-3 items-center justify-center text-white"
    >
      {title}
      {arrow === "true" && <ArrowRightIcon className=" w-5 h-5 text-white" />}
    </button>
  );
};

const BorderGradientButton = ({
  title,
  arrow = "false",
  color,
  onClick,
}: any) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`py-[10px] px-8 w-full rounded-full cursor-pointer gradient-border  ${color} after:top-[3px] after:bottom-[3px] after:left-[3px] after:right-[3px] flex gap-3 justify-center items-center `}
    >
      {title}
      {arrow === "true" && <ArrowRightIcon className=" w-5 h-5" />}
    </button>
  );
};

export { OrangeButton, BorderGradientButton };
