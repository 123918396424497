import { NextPage } from "next";
import React from "react";
import selectedTextHighlight from "../../services/selectedTextHighlight";

interface Props {
  children?: any;
  ngClass?: string;
  style?: any;
}

interface HeadDescProps {
  textHeader: string;
  highlightText: string;
  description: string;
  ngClass?: string;
  children: any;
  tHeadColor?: any;
  extraHeading?: string;
  extraHeadingClass?: string;
  desStyle?: string;
  lastHeading?: string;
}

const BlankSection: NextPage<Props> = ({ children = "", ngClass, style }) => {
  return (
    <section className={ngClass} style={style}>
      <div className=" px-6 mx-auto max-w-7xl lg:px-4 2xl:px-0 w-full">
        {children}
      </div>
    </section>
  );
};

const SectionWithHeading: NextPage<any> = ({ heading, ngClass, children }) => {
  return (
    <BlankSection ngClass={ngClass}>
      <div className="text-center mb-12 lg:mb-24">
        <h1 className="tracking-tight text-gray-900 relative">{heading}</h1>
      </div>
      <div>{children}</div>
    </BlankSection>
  );
};

const SectionWithHeadingDesc: NextPage<HeadDescProps> = ({
  textHeader,
  highlightText,
  description,
  ngClass,
  children,
  tHeadColor,
  desStyle = "text-primary",
}) => {
  return (
    <BlankSection ngClass={ngClass}>
      <div className=" flex flex-col sm:px-4 w-full gap-8 max-w-4xl mx-auto">
        <h2 className={`text-center relative ${tHeadColor}`}>
          <span className="after:bg-secondary after:h-[6px] after:content-[''] after:absolute after:w-[100px] after:-bottom-[15px] after:left-1/2 after:-translate-x-1/2  after:rounded">
            <span className={` mr-2 `}>
              {selectedTextHighlight(
                textHeader,
                highlightText,
                "text-secondary"
              )}
            </span>
          </span>
        </h2>
        <p className={`py-4 px-4 text-center ${desStyle}`}>
          {description}
        </p>
      </div>
      <div>{children}</div>
    </BlankSection>
  );
};

const SectionWithTopBottomHeadingDesc: NextPage<HeadDescProps> = ({
  textHeader,
  highlightText,
  description,
  ngClass,
  children,
}) => {
  return (
    <BlankSection ngClass={ngClass}>
      <div className="sm:px-4 w-full text-center">
        <h1 className="relative">
          <span className="block">{textHeader}</span>{" "}
          <span className="text-secondary-500 after:content-[''] after:absolute after:w-24 md:after:w-32 after:h-1 md:after:h-2 after:top-[100px] md:after:top-[150px] after:left-[40%] md:after:left-[45%] after:border-4 md:after:border-4 after:border-secondary-500">
            {highlightText}
          </span>
        </h1>

        <p className="text-primary-500 py-12 px-4 md:px-[16rem]">
          {description}
        </p>
      </div>
      <div>{children}</div>
    </BlankSection>
  );
};

const BlankSectionNoYSpacing: NextPage<Props> = ({ children, ngClass }) => {
  return (
    <section className={ngClass}>
      <div className="px-4 mx-auto max-w-7xl lg:px-8">{children}</div>
    </section>
  );
};

const BlankSectionNoXSpacing: NextPage<Props> = ({ children, ngClass }) => {
  return (
    <section className={ngClass}>
      <div className="py-8 xl:py-16">{children}</div>
    </section>
  );
};

const BlankSectionNoSpacing: NextPage<Props> = ({ children, ngClass }) => {
  return (
    <section className={ngClass}>
      <div>{children}</div>
    </section>
  );
};

export {
  BlankSection,
  SectionWithHeading,
  SectionWithHeadingDesc,
  BlankSectionNoYSpacing,
  SectionWithTopBottomHeadingDesc,
  BlankSectionNoXSpacing,
  BlankSectionNoSpacing,
};
