import React from 'react'

const BgGlow = () => {
  return (
    <div className='w-full h-full absolute top-0 left-0 overflow-hidden -z-20'>
        <div className='relative w-full h-full '>
            <div className='w-[863px] opacity-25  absolute top-1/2 -translate-y-1/2 -left-[180px] '>
                <svg width="863" height="958" viewBox="0 0 863 958" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_f_2993_7531)">
                <path d="M480.477 504.166C547.683 504.166 602.164 449.685 602.164 382.479C602.164 315.272 547.683 260.791 480.477 260.791C413.27 260.791 358.789 315.272 358.789 382.479C358.789 449.685 413.27 504.166 480.477 504.166Z" fill="#FF5900"/>
                <path d="M381.712 697.831C448.919 697.831 503.4 643.35 503.4 576.144C503.4 508.937 448.919 454.456 381.712 454.456C314.506 454.456 260.025 508.937 260.025 576.144C260.025 643.35 314.506 697.831 381.712 697.831Z" fill="#BD10E0"/>
                </g>
                <defs>
                <filter id="filter0_f_2993_7531" x="0.0249023" y="0.791016" width="862.139" height="957.04" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="130" result="effect1_foregroundBlur_2993_7531"/>
                </filter>
                </defs>
                </svg>
            </div>
            <div className='w-[814px] opacity-25  absolute top-1/2 -translate-y-1/2 -right-[180px] '>
                <svg width="814" height="873" viewBox="0 0 814 873" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_f_2993_7527)">
                <path d="M364.227 468.593C421.595 468.593 468.102 422.086 468.102 364.718C468.102 307.349 421.595 260.843 364.227 260.843C306.858 260.843 260.352 307.349 260.352 364.718C260.352 422.086 306.858 468.593 364.227 468.593Z" fill="#FF9F0F"/>
                <path d="M449.271 612.922C506.639 612.922 553.145 566.415 553.145 509.047C553.145 451.678 506.639 405.172 449.271 405.172C391.902 405.172 345.396 451.678 345.396 509.047C345.396 566.415 391.902 612.922 449.271 612.922Z" fill="#BD10E0"/>
                </g>
                <defs>
                <filter id="filter0_f_2993_7527" x="0.351562" y="0.842773" width="812.794" height="872.079" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="130" result="effect1_foregroundBlur_2993_7527"/>
                </filter>
                </defs>
                </svg>
            </div>

        </div>
    </div>
  )
}

export default BgGlow