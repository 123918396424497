import React, { useEffect, useState } from "react";
import { SectionWithHeadingDesc } from "../layouts/sections";
import VGBG from "../vgbg";
import Image from "next/image";
import ProcessLine from "../../assets/images/Services/ServiceDetails/ProcessLine.svg";
import { strapiImageUrl } from "../../services/generateImageUrl";

const Index = (props: { processData: any }) => {
  const { processData } = props;
  const textHeader = processData?.textDesc?.TextHeader;
  const highlightText = processData?.textDesc?.highlightText;
  const description = processData?.textDesc?.Description;
  const process = processData?.workingProcess;
  const sortedProcess = process?.sort((a: any, b: any) => a.order - b.order);
  const isOdd = sortedProcess.length % 2 !== 0;

  const [isActive, setIsActive] = useState(0);
  const [title, setTitle] = useState(sortedProcess);
  const [indexValue, setIndexValue] = useState(1);

  useEffect(() => {
    if (sortedProcess) setTitle(sortedProcess[0]);
  }, [sortedProcess]);

  const onBtnClick = (index: number) => {
    setIsActive(index);
    setTitle(sortedProcess[index]);
    setIndexValue(index + 1);
  };

  return (
    <SectionWithHeadingDesc
      ngClass="relative bg-no-repeat bg-cover h-full flex items-center flex-col py-[80px] justify-center"
      textHeader={textHeader}
      highlightText={highlightText}
      description={description}
    >
      <VGBG />
      <div className="flex gap-12 items-center flex-col">
        <div className="grid grid-cols-2 md:flex gap-4 lg:mt-12 flex-wrap lg:[flexWrap:nowrap] justify-center order-2 lg:order-1">
          {sortedProcess?.map((item: any, index: number) => (
            <div
              key={index}
              className={`flex gap-4 items-center lg:py-4 lg:flex-row flex-col ${isOdd && index === sortedProcess.length - 1 ? "col-span-2" : ""}`}
            >
              <button
                type="button"
                onClick={() => onBtnClick(index)}
                className={`w-full lg:w-40 p-6 rounded-2xl flex flex-col gap-5 items-center justify-start text-button  border-4 h-full ${
                  isActive === index ? "border-secondary bg-dark-gradient text-white" : "border-4 text-primary bg-white border-primary border-opacity-[8%]"
                } `}
              >
                <div className=" w-12 h-12">
                  {item?.icon?.media?.data?.attributes?.url && (
                    <Image
                      src={strapiImageUrl(
                        item?.icon?.media?.data?.attributes?.url
                      )}
                      alt="Hero Image"
                      height={100}
                      width={100}
                    />
                  )}
                </div>
                <h4 className="select-none"> {item?.title} </h4>
              </button>

              {/* Connector Line */}
              <div className="lg:rotate-0 rotate-90 mt-4 lg:block hidden">
                {index !== sortedProcess.length - 1 && (
                  <Image
                    src={ProcessLine}
                    alt="Hero Image"
                    width={100}
                    height={100}
                  />
                )}
              </div>
            </div>
          ))}
        </div>


          {/* Description box */}
        <div className="flex flex-col order-1 lg:order-2 py-6 px-8 gap-4 items-start rounded-3xl border-b-4 border-secondary bg-primary bg-opacity-[8%] w-full lg:w-auto">
          <h4 className="text-center">{indexValue + ". " + title?.title}</h4>
          <div className=" flex gap-4 md:flex-row flex-col">
            {title?.content?.map((item: any, index: number) => (
              <div className="flex items-center" key={index + 1}>
                <div className=" bg-secondary rounded-[50%] h-2 w-2 mr-4" />{" "}
                <p className=" text-body font-montserratR"> {item?.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </SectionWithHeadingDesc>
  );
};

export default Index;
