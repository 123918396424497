import React from "react";

/**
 * To highlight selected text
 * 
 * @param inputString : string
 * @param wordsToHighlight : array
 * @param className : string
 * @returns 
 */
const selectedTextHighlight = (inputString: string, wordsToHighlight: string, className: string) => {
    const highlightArray = wordsToHighlight?.split(" ")
    highlightArray?.forEach(word => {
        inputString = inputString?.replace(new RegExp(word, 'g'), `<span class="${className}">${word}</span>`);
    });
    return (<span dangerouslySetInnerHTML={{ __html: inputString }} />)
}

export default selectedTextHighlight