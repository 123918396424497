import React from 'react'

const index = () => {
  return (
    <div className="quote-background opacity-50 -z-20">
          <div className="stars"></div>
          <div className="stars"></div>
          <div className="stars"></div>
          <div className="stars"></div>
          <div className="stars"></div>
        </div>
  )
}

export default index
