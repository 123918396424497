import React from "react";

import { NextPage } from "next";
import { OrangeButton } from "../ButtonsDesign";
import Link from "next/link";
import BgStarAnimation from "../BgStarAnimation";
import selectedTextHighlight from "../../services/selectedTextHighlight";

interface Props {
  ngClass: string;
  textHeader: string;
  highlightText: string;
  description: string;
  px?: string;
  button: any;
}

const QuoteSection: NextPage<Props> = ({
  ngClass,
  textHeader,
  highlightText,
  description,
  px = "px-4",
  button,
}) => {
  return (
    <div className="relative lg:min-h-[580px] ">
      <BgStarAnimation />
      <div className="globe-section lg:w-[1000px] lg:h-[580px] xl: left-0 absolute hidden lg:block"></div>

      <div className={`max-w-7xl mx-auto flex justify-center lg:justify-end my-0 md:h-[480px]`}>
        <div
          className={`md:w-[50%] flex flex-wrap gap-y-10 my-16 lg:my-24 py-10 ${px}`}
        >
          <div className="flex flex-col items-center lg:items-start gap-10 z-10">
            <h2 className="relative text-white text-center lg:text-left">
              <span
                className={` after:bg-secondary after:h-[6px] after:absolute after:content-[''] after:w-[100px] ${ngClass} after:left-1/2 after:-translate-x-1/2 lg:after:left-[0%] lg:after:-translate-x-0  after:rounded`}
              >
                {selectedTextHighlight(
                  textHeader,
                  highlightText,
                  "text-orange"
                )}
              </span>
            </h2>
            <p className="text-white text-center lg:text-left max-w-2xl lg:max-w-full">
              {description}
            </p>
            {button && (
              <Link href={button?.href}>
                <a>
                  <OrangeButton title={button?.Label} arrow="true" />
                </a>
              </Link>
            )}
          </div>
        </div>
      </div>
      {/* <div className='flex justify-center pt-4 h-[60px]'>
                <Image  className='animate-bounce' src={chevronDown} alt="chevron-down" />
            </div> */}
    </div>
  );
};

export default QuoteSection;
